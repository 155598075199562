<template>
  <div>
    <div class="man-title">
      菜单管理
      <el-button @click="add" class="btn" type="primary" size="mini">新增菜单</el-button>
    </div>
    <div class="list-box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="title" label="菜单名称"></el-table-column>
        <el-table-column prop="url" label="地址"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-dropdown placement="bottom-start" trigger="click">
              <span @click.stop="">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                <el-dropdown-item><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    edit(row) {
      this.$router.push({ name: 'menuManageEdit', params: row })
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("menuManageDelete", {
          id: row.id,
        }).then((res) => {
          this.loadList();
        });
      });
    },
    add() {
      this.$router.push({ name: 'menuManageAdd' })
    },
    loadList() {
      this.$ajax.get("menuManage").then((res) => {
        this.tableData = res.data;
      });
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>

<style lang="less" scoped>
.btn {
  float: right;
}
</style>